<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <div class="flex h-screen ">
      <loading
        :active.sync="isSubmitted"
        color="#ff9300"
      />
      <form
        class="w-full max-w-sm m-auto"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <div class="px-6 pt-6 pb-6">
          <h3 class="text-3xl font-bold mb-6">
            Confirm your account
          </h3>
          <div class="w-full mb-6">
            <p class="font-light">
              We have sent a code by email to
              {{ confirmationEmail.obfuscatedEmail }}. Enter it below to confirm
              your registration.
            </p>
          </div>

          <div class="w-full">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >Code</label>

            <ValidationProvider
              v-slot="{ errors }"
              name="code"
              rules="required|numeric|length:6"
              mode="eager"
            >
              <input
                v-model="confirm.code"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none"
                type="text"
                placeholder="123456"
              >
              <span class="text-red-500 text-xs inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div> 

          <button
            class="w-full shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-3 px-4 rounded"
            type="submit"
          >
            Confirm registration
          </button>
          <p class="font-light my-4 text-sm">
            Didn't get a code?
            <span
              class="font-light mb-6 text-sm text-orange-500 underline hover:cursor-pointer"
              @click="onConfirmResend"
            >
              Resend
            </span>
          </p>
        </div>
      </form>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import {
  CONFIRM_REGISTRATION,
  CONFIRM_REGISTRATION_RESEND,
} from "@/store/actions.type.js";

export default {
  name: "ConfirmRegistration",
  components: {
    Loading,
  },
  data() {
    return {
      confirm: {
        code: "",
      },
      passwordPlaceholder: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022",
      isSubmitted: false,
    };
  },
  computed: {
    ...mapGetters(["confirmationEmail"]),
  },
  methods: {
    async onSubmit() {
      this.isSubmitted = true;

      await this.$store.dispatch(CONFIRM_REGISTRATION, {
        username: this.confirmationEmail.email,
        code: this.confirm.code,
      });

      this.isSubmitted = false;

      this.$router.push({ name: "login" });
    },
    async onConfirmResend() {
      this.isSubmitted = true;
      await this.$store.dispatch(CONFIRM_REGISTRATION_RESEND, {
        username: this.confirmationEmail.email,
      });
      this.isSubmitted = false;
    },
  },
};
</script>
