<template>
  <Auth>
    <Confirm />
  </Auth>
</template>

<script>
import Auth from "./Auth";
import Confirm from "@/components/Auth/Confirm";

export default {
  name: "ConfirmView",
  components: {
    Auth,
    Confirm,
  },
};
</script>
