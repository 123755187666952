var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "div",
              { staticClass: "flex h-screen " },
              [
                _c("loading", {
                  attrs: { active: _vm.isSubmitted, color: "#ff9300" },
                  on: {
                    "update:active": function($event) {
                      _vm.isSubmitted = $event
                    }
                  }
                }),
                _c(
                  "form",
                  {
                    staticClass: "w-full max-w-sm m-auto",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "px-6 pt-6 pb-6" }, [
                      _c("h3", { staticClass: "text-3xl font-bold mb-6" }, [
                        _vm._v(" Confirm your account ")
                      ]),
                      _c("div", { staticClass: "w-full mb-6" }, [
                        _c("p", { staticClass: "font-light" }, [
                          _vm._v(
                            " We have sent a code by email to " +
                              _vm._s(_vm.confirmationEmail.obfuscatedEmail) +
                              ". Enter it below to confirm your registration. "
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "w-full" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                              attrs: { for: "grid-password" }
                            },
                            [_vm._v("Code")]
                          ),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "code",
                              rules: "required|numeric|length:6",
                              mode: "eager"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.confirm.code,
                                            expression: "confirm.code"
                                          }
                                        ],
                                        staticClass:
                                          "appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none",
                                        attrs: {
                                          type: "text",
                                          placeholder: "123456"
                                        },
                                        domProps: { value: _vm.confirm.code },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.confirm,
                                              "code",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-red-500 text-xs inline-block"
                                        },
                                        [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "w-full shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-3 px-4 rounded",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(" Confirm registration ")]
                      ),
                      _c("p", { staticClass: "font-light my-4 text-sm" }, [
                        _vm._v(" Didn't get a code? "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-light mb-6 text-sm text-orange-500 underline hover:cursor-pointer",
                            on: { click: _vm.onConfirmResend }
                          },
                          [_vm._v(" Resend ")]
                        )
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }